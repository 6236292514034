@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

html,
body,
#root,
.app {
	height: 100%;
	width: 100%;
	font-family: "Inter", sans-serif;
}

.content {
	height: 100%;
	width: 100%;
	position: relative;
	background-color: #f7f7f7;
  flex: 5
}

.app {
	position: relative;
}

::-webkit-scrollbar {
	width: 10px;
}

.pageHeader {
	font-size: 30px;
}

.add-button {
	background-color: #fcb200 !important;
	box-shadow: none !important;
	color: white !important;
}

.add-button:hover {
	background-color: #000 !important;
}

.pro-sidebar {
	width: 250px !important;
	min-width: 250px !important;
}

.pro-sidebar.collapsed {
	width: 80px !important;
	min-width: 80px !important;
}

.navlogo {
	height: 30px;
}

a {
	text-decoration: none;
}

/* .css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: 20000 !important;
} */

/* Screen below 500px */
@media (max-width: 500px) {
	.content {
		width: 83%;
	}
	.pro-sidebar.collapsed {
		width: 55px !important;
		min-width: 55px !important;
	}
	.pro-sidebar {
		width: 170px !important;
		min-width: 170px !important;
	}
	.add-button {
		width: 100% !important;
	}
	.pageHeader {
		font-size: 20px;
	}
	.navlogo {
		height: 30px;
	}
}

.swiper {
	width: 100%;
	height: 410px;
	border-radius: 5px;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-button-next, 
.swiper-button-prev {
	color:#fcb200 !important;
}

